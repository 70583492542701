import React, {Component} from "react";
import JSONEditor from "jsoneditor";
import {Button, Form, Grid, Header, Icon, Message, Select} from "semantic-ui-react";
import StateBasedPlayDataProvider from "../../../Services/StateBasedPlayDataProvider";
import ContingentButton from "../../ContingentButton";

StateBasedPlayDataProvider.init({baseURL: process.env.SBP_URL});

export default class StateBasedPlayMetadata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metadata: {},
            currentEditorText: {},
            lastEditorText: {},
            selectedAttribute: "",
            attributeOptions: [],
            loading: false,
            saving: false,
            saveComplete: false
        };
    }

    componentDidMount() {
        const options = {
            mode: 'text',
            onChange: () => {
                const text = this.jsoneditor.get();
                if (text !== this.state.currentEditorText) {
                    this.setState({currentEditorText: text, saveComplete: false});
                }
            }
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.currentEditorText);

        this.refreshMetadata();

        if (this.state.metadata) {
            this.generateAttributeOptions();
        }
    }

    generateAttributeOptions = () => {
        this.setState({attributeOptions: Object.keys(this.state.metadata).map(option => {
                return {key: option, value: option, text: option};
            })
        }, () => {
            console.log("(StateBasedPlayMetadata.generateAttributeOptions) setting attribute options to ", this.state.attributeOptions, "from metadata", this.state.metadata);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(`(StateBasedPlayMetadata.componentDidUpdate) Started.`, prevProps, prevState, this.props, this.state);
        if (this.state.metadata !== prevState.metadata) {
            this.generateAttributeOptions();
        }

        if (this.state.selectedAttribute !== prevState.selectedAttribute) {
            const text = this.state.metadata.hasOwnProperty(this.state.selectedAttribute) ? this.state.metadata[this.state.selectedAttribute] : {};
            this.setState({currentEditorText: text, lastEditorText: text, saveComplete: false}, () => {
                this.jsoneditor.set(this.state.currentEditorText);
            });
        }
    }

    refreshMetadata = () => {
        this.setState({loading: true}, () => {
            StateBasedPlayDataProvider.getMetadata().then(response => {
                this.setState({metadata: response, loading: false});
            });
        });
    };

    saveMetadata = () => {
        this.setState({lastEditorText: this.state.currentEditorText, saving: true, saveComplete: false});
        StateBasedPlayDataProvider.setMetadata(this.state.selectedAttribute, this.state.currentEditorText).then(response => {
            this.setState({saving: false, saveComplete: true});
            console.log("(StateBasedPlayMetadata.saveMetadata) response", response);
            this.refreshMetadata();
        })
    };

    resetValue = () => {
        this.setState({currentEditorText: this.state.lastEditorText, saveComplete: false}, () => {
            this.jsoneditor.set(this.state.currentEditorText);
        });
    };

    render() {
        return (
            <Grid className="masterContainer">
                <Grid.Row>
                    <Grid.Column className="masterContainer">
                        <Grid.Row style={{paddingBottom: "3ch"}}>
                            {
                                this.state.saveComplete ?
                                    <Message color="green" icon>
                                        <Icon name="check" />
                                        <Message.Content>Metadata has been saved for the attribute {this.state.selectedAttribute}. It may take a minute for the metadata to be refreshed.</Message.Content>
                                    </Message> : ""
                            }
                            {
                                this.state.loading ?
                                    <Message color="yellow" icon>
                                        <Icon loading name="spinner" />
                                        <Message.Content>Loading...</Message.Content>
                                    </Message> : ""
                            }
                            {
                                this.state.saving ?
                                    <Message color="blue" icon>
                                        <Icon loading name="spinner" />
                                        <Message.Content>Saving metadata...</Message.Content>
                                    </Message> : ""
                            }
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Attribute"
                                        control={Select}
                                        fluid
                                        options={this.state.attributeOptions}
                                        value={this.state.selectedAttribute}
                                        onChange={(event, {value}) => this.setState({selectedAttribute: value})}
                                        onAddItem={(event, {value}) => this.setState({metadata: Object.assign({}, this.state.metadata, {[value]: {}})})}
                                        search
                                        allowAdditions
                                    />
                                    <Form.Field width={2}>
                                        <label>&nbsp;</label>
                                        <ContingentButton
                                            fluid
                                            allPermissions={this.props.permissions}
                                            service={this.props.service}
                                            module={this.props.module}
                                            scope="all"
                                            user={this.props.user}
                                            onClick={
                                                () => {
                                                    this.setState({saveComplete: false}, () => this.refreshMetadata());
                                                }
                                            }>Refresh</ContingentButton>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Row>
                        <Grid.Row>
                            <h4>Attribute Data</h4>
                            <div style={{height: "56vh"}} ref={elem => this.container = elem}/>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "3ch"}}>
                            <Button onClick={this.saveMetadata} primary disabled={!this.state.selectedAttribute}>Save Metadata</Button>
                            <Button onClick={this.resetValue} disabled={!this.state.selectedAttribute}>Reset Value</Button>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
};
