import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export default function ContentSchemataSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredSchemata, setFilteredSchemata] = useState([]);

    useEffect(function updateFilteredGeofences() {
        if (filter.length > 0) {
            const updatedSchemata = [];
            for (const schema of props.contentSchemata) {
                if (schema.hasOwnProperty("name") && schema.name.toString().toLowerCase().includes(filter)) {
                    updatedSchemata.push(schema);
                } else if (schema.hasOwnProperty("description") && schema.description.toString().includes(filter)) {
                    updatedSchemata.push(schema);
                }
            }
            setFilteredSchemata(updatedSchemata);
        } else {
            setFilteredSchemata(props.contentSchemata);
        }
    }, [filter, JSON.stringify(props.contentSchemata)]);

    const onDragEnd = result => {
        if (filter) {
            props.toast("Error", "You must clear the filter before you can modify the order of content schemata.");
            return;
        }

        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        const updatedSchemata = props.contentSchemata.slice();

        const [removed] = updatedSchemata.splice(source.index, 1);
        updatedSchemata.splice(destination.index, 0, removed);
        props.setContentSchemata(updatedSchemata);
    };

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon secondary onClick={() => props.setNewClicked(true)} fluid><Icon name="plus"/> Create New</Button>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshContentSchemata()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72.5vh", overflowY: "auto"}}>
                    {
                        props.loading ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading Content Schemata...
                                </Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {
                                        filteredSchemata.length > 0 ?
                                            <Droppable droppableId={"droppable"}>
                                                {
                                                    provided =>
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {
                                                                filteredSchemata.map((schema, index) =>
                                                                    <Draggable key={`content-schema-${index}`} draggableId={`content-schema-${index}`} index={index}>
                                                                        {
                                                                            provided =>
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <Menu.Item
                                                                                        innerRef={provided.innerRef}
                                                                                        {...provided.droppableProps}
                                                                                        active={schema.name && props.selectedSchema.name ? props.selectedSchema.name === schema.name : JSON.stringify(props.selectedSchema) === JSON.stringify(schema)}
                                                                                        onClick={
                                                                                            () => {
                                                                                                console.log(schema);
                                                                                                props.setSelectedSchema(schema);
                                                                                            }
                                                                                        }
                                                                                        fluid
                                                                                    >
                                                                                        <h5>{schema.name}</h5>
                                                                                        <>{schema.description}</>
                                                                                        {provided.placeholder}
                                                                                    </Menu.Item>
                                                                                </div>
                                                                        }
                                                                    </Draggable>
                                                                )
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                }
                                            </Droppable> :
                                            <Message icon color="yellow">
                                                <Icon name="warning" />
                                                <Message.Content>No content schemata match your current search filter.</Message.Content>
                                            </Message>
                                    }
                                </DragDropContext>
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};
