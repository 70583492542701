import React, {useState, useEffect} from "react";
import {Button, Container, Divider, Dropdown, Form, Icon, Input, Label, Message, Modal, Popup, Segment, TextArea} from "semantic-ui-react";
import AraDataProvider from "../../../Services/ARADataProvider";
import SchemaEditor from "../../SchemaEditor/SchemaEditor";
import ContingentButton from "../../ContingentButton";

AraDataProvider.init({baseURL: process.env.PROD_ARA});

export default function ListSchemataDetailView(props) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [remainingNetworkOptions, setRemainingNetworkOptions] = useState([]);
    const [appIds, setAppIds] = useState([]);
    const [selectedAppIds, setSelectedAppIds] = useState([]);
    const [appIdsToAdd, setAppIdsToAdd] = useState([]);
    const [remainingAppIdOptions, setRemainingAppIdOptions] = useState([]);
    const [networks, setNetworks] = useState({});
    const [networksToAdd, setNetworksToAdd] = useState([]);
    const [selectedNetworks, setSelectedNetworks] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsToAdd, setProductsToAdd] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [remainingProductOptions, setRemainingProductOptions] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [platformsToAdd, setPlatformsToAdd] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [remainingPlatformOptions, setRemainingPlatformOptions] = useState([]);
    const [selectedListNames, setSelectedListNames] = useState([]);
    const [listNameToAdd, setListNameToAdd] = useState("");
    const [index, setIndex] = useState(-1);
    const [schema, setSchema] = useState({});
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function updateUserCanEdit() {
        const userPermissions = [];
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            if (props.user.hasOwnProperty("authz") && Array.isArray(props.user.authz)) {
                for (const permission of props.permissions.slice()) {
                    if (props.user.authz.includes(permission.urn) && permission.service === props.service && permission.module === props.module) {
                        userPermissions.push(Object.assign({}, permission));
                    }
                }

                for (const permission of userPermissions.slice()) {
                    if ([selectedNetworks].concat("all").includes(permission.scope) && permission.role === "editor") {
                        updatedUserCanEdit = true;
                        break;
                    }
                }
            }
        }

        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions), JSON.stringify(selectedNetworks)]);

    useEffect(function onSchemaUpdate() {
        const isNameUpdated = (props.selectedSchema.name || "") !== name;
        const isDescriptionUpdated = (props.selectedSchema.description || "") !== description;
        const isAppIdsUpdated = JSON.stringify(props.selectedSchema.appIds || []) !== JSON.stringify(selectedAppIds);
        const isNetworksUpdated = JSON.stringify(props.selectedSchema.networks || []) !== JSON.stringify(selectedNetworks);
        const isProductsUpdated = JSON.stringify(props.selectedSchema.products || []) !== JSON.stringify(selectedProducts);
        const isPlatformsUpdated = JSON.stringify(props.selectedSchema.platforms || []) !== JSON.stringify(selectedPlatforms);
        const isListNamesUpdated = JSON.stringify(props.selectedSchema.listNames || []) !== JSON.stringify(selectedListNames);
        const isSchemaUpdated = JSON.stringify(props.selectedSchema.schema || {}) !== JSON.stringify(schema);

        props.setIsSelectedSchemaUpdated(isNameUpdated || isDescriptionUpdated || isAppIdsUpdated || isNetworksUpdated || isProductsUpdated || isPlatformsUpdated || isListNamesUpdated || isSchemaUpdated);
    }, [JSON.stringify(props.selectedSchema), name, description, JSON.stringify(selectedAppIds), JSON.stringify(selectedNetworks), JSON.stringify(selectedPlatforms), JSON.stringify(selectedProducts), JSON.stringify(schema)]);

    useEffect(function loadAppIds() {
        AraDataProvider.getAll().then(response => {
           const updatedNetworks = new Set();
           const updatedPlatforms = new Set();
           const updatedProducts = new Set();
           const updatedAppIds = [];

           for (const appId of response) {
               if (appId.hasOwnProperty("appId")) {
                   updatedAppIds.push(appId.appId);
               }
               if (appId.hasOwnProperty("network")) {
                   updatedNetworks.add(appId.network);
               }
               if (appId.hasOwnProperty("platform")) {
                   updatedPlatforms.add(appId.platform);
               }
               if (appId.hasOwnProperty("product")) {
                   updatedProducts.add(appId.product);
               }
           }
           setAppIds(updatedAppIds);
           setNetworks(Array.from(updatedNetworks));
           setPlatforms(Array.from(updatedPlatforms));
           setProducts(Array.from(updatedProducts));
        });
    }, []);

    useEffect(function onSelectedSchemaChange() {
        setName(props.selectedSchema.hasOwnProperty("name") ? props.selectedSchema.name : "");
        setDescription(props.selectedSchema.hasOwnProperty("description") ? props.selectedSchema.description : "");
        setSchema(props.selectedSchema.hasOwnProperty("schema") ? Object.assign({}, props.selectedSchema.schema) : {});
        setSelectedListNames(props.selectedSchema.hasOwnProperty("listNames") ? props.selectedSchema.listNames : []);
        setSelectedAppIds(props.selectedSchema.hasOwnProperty("appIds") ? props.selectedSchema.appIds : []);
        setSelectedNetworks(props.selectedSchema.hasOwnProperty("networks") ? props.selectedSchema.networks : []);
        setSelectedProducts(props.selectedSchema.hasOwnProperty("products") ? props.selectedSchema.products : []);
        setSelectedPlatforms(props.selectedSchema.hasOwnProperty("platforms") ? props.selectedSchema.platforms : []);
        setIndex(props.selectedSchema.index);
    }, [JSON.stringify(props.selectedSchema)]);

    useEffect(function updateAppIdOptions() {
        const updatedAppIdOptions = [];
        for (const appId of appIds) {
            if (!selectedAppIds.includes(appId)) {
                updatedAppIdOptions.push({key: appId, text: appId, value: appId});
            }
        }
        setRemainingAppIdOptions(updatedAppIdOptions);
    }, [JSON.stringify(appIds), JSON.stringify(selectedAppIds)]);

    useEffect(function updateNetworkOptions() {
        const updatedNetworkOptions = [];

        if (Array.isArray(networks)) {
            for (const network of networks) {
                if (!selectedNetworks.includes(network)) {
                    updatedNetworkOptions.push({key: network, text: network, value: network});
                }
            }
        }

        setRemainingNetworkOptions(updatedNetworkOptions);
    }, [JSON.stringify(networks), JSON.stringify(selectedNetworks)]);

    useEffect(function updateProductOptions() {
        const updatedProductOptions = [];
        if (Array.isArray(products)) {
            for (const product of products) {
                if (!selectedProducts.includes(product)) {
                    updatedProductOptions.push({key: product, text: product, value: product});
                }
            }
        }

        setRemainingProductOptions(updatedProductOptions);
    }, [JSON.stringify(products), JSON.stringify(selectedProducts)]);

    useEffect(function updatePlatformOptions() {
        const updatedPlatformOptions = [];

        if (Array.isArray(platforms)) {
            for (const platform of platforms) {
                if (!selectedPlatforms.includes(platform)) {
                    updatedPlatformOptions.push({key: platform, text: platform, value: platform});
                }
            }
        }

        setRemainingPlatformOptions(updatedPlatformOptions);
    }, [JSON.stringify(platforms), JSON.stringify(selectedPlatforms)]);

    const onClickSave = () => {
        const updatedSchema = {
            name,
            description,
            ...selectedAppIds && {appIds: selectedAppIds},
            ...selectedNetworks && {networks: selectedNetworks},
            ...selectedProducts && {products: selectedProducts},
            ...selectedPlatforms && {platforms: selectedPlatforms},
            ...selectedListNames && {listNames: selectedListNames},
            schema: btoa(JSON.stringify(schema)),
            index
        };

        props.onClickSave(updatedSchema);
    };

    return (
        <Container>
            {
                props.saving ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving list schemata...</Message.Content>
                    </Message> :
                    props.saveErrorMessage ?
                        <Message icon color="red">
                            <Icon name="exclamation" />
                            <Message.Content>{props.saveErrorMessage}</Message.Content>
                        </Message> :
                        props.saveSuccessMessage ?
                            <Message icon color="green">
                                <Icon name="save outline" />
                                <Message.Content>{props.saveSuccessMessage}</Message.Content>
                            </Message> : ""
            }
            {
                !props.saving ?
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Popup
                                    open={userCanEdit && props.unsavedChanges && (props.newClicked || Object.keys(props.selectedSchema).length > 0 ? name.length > 0 && !props.schemaNames.includes(name) : true)}
                                    content="You have made unsaved changes."
                                    trigger={
                                        <ContingentButton
                                            icon
                                            color="green"
                                            onClick={() => onClickSave()}
                                            disabled={!props.unsavedChanges}
                                            allPermissions={props.permissions}
                                            service={props.service}
                                            module={props.module}
                                            scope={selectedNetworks}
                                            user={props.user}
                                        ><Icon name="save outline"/> Save List Schemata</ContingentButton>
                                    }
                                    position="right center"
                                />
                            </Form.Field>
                            <Form.Field>
                                <Modal
                                    size="large"
                                    open={deletionModalOpen}
                                    onOpen={() => setDeletionModalOpen(true)}
                                    onClose={() => setDeletionModalOpen(false)}
                                    style={{position: "relative"}}
                                    trigger={
                                        <ContingentButton
                                            color="red"
                                            floated="right"
                                            disabled={!userCanEdit && name.length < 1}
                                            service={props.service}
                                            module={props.module}
                                            allPermissions={props.permissions}
                                            user={props.user}
                                            scope={selectedNetworks}
                                        >Delete Schema</ContingentButton>}
                                >
                                    <Modal.Header>Confirm List Schema Deletion</Modal.Header>
                                    <Modal.Content>
                                        <Modal.Description>Are you sure you want to delete the "{name}" schema?</Modal.Description>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={() => setDeletionModalOpen(false)} secondary>Cancel</Button>
                                        <Button onClick={() => {
                                            props.onClickDelete(index);
                                            setDeletionModalOpen(false);
                                        }} primary>Delete Schema</Button>
                                    </Modal.Actions>
                                </Modal>
                            </Form.Field>
                        </Form.Group>
                    </Form> : ""
            }
            {
                Object.keys(props.selectedSchema).length < 1 && !props.newClicked ?
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>Select a list schema or click the "Create New" button to continue.</Message.Content>
                    </Message> :
                    <>
                        <Form>
                            <Form.Group widths="equal">
                                <Popup
                                    trigger={
                                        <Form.Field
                                            label="Name"
                                            control={Input}
                                            value={name}
                                            onChange={(event, {value}) => setName(value)}
                                        />
                                    }
                                    open={name.length < 1 || props.schemaNames.includes(name)}
                                    content="List schemata require a unique name."
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Description"
                                    control={TextArea}
                                    value={description}
                                    onChange={(event, {value}) => setDescription(value)}
                                />
                            </Form.Group>
                            <Segment basic style={{background: selectedAppIds.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                <Divider horizontal>App IDs</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select App IDs to Add"
                                        control={Dropdown}
                                        options={remainingAppIdOptions}
                                        value={appIdsToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        onChange={(event, {value}) => setAppIdsToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedAppIds = selectedAppIds.concat(appIdsToAdd);
                                            setSelectedAppIds(updatedAppIds);
                                            setAppIdsToAdd([]);
                                        }}
                                        content="Add Selected App IDs"
                                        fluid
                                    />
                                </Form.Group>
                                <Label.Group>
                                    {
                                        selectedAppIds.map((appId, index) =>
                                            <Label
                                                as="a"
                                                circular
                                                key={`app-id-${appId}`}
                                                onClick={() => {
                                                    let updatedAppIds = selectedAppIds.slice();
                                                    updatedAppIds.splice(index, 1);
                                                    setSelectedAppIds(updatedAppIds);
                                                }}
                                            >
                                                {appId}
                                                <Icon name="delete" />
                                            </Label>
                                        )
                                    }
                                </Label.Group>
                            </Segment>
                            <Segment basic style={{background: selectedNetworks.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                <Divider horizontal>Networks</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Networks to Add"
                                        control={Dropdown}
                                        options={remainingNetworkOptions}
                                        value={networksToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        onChange={(event, {value}) => setNetworksToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedNetworks = selectedNetworks.concat(networksToAdd);
                                            setSelectedNetworks(updatedNetworks);
                                            setNetworksToAdd([]);
                                        }}
                                        content="Add Selected Networks"
                                        fluid
                                    />
                                </Form.Group>
                                <Label.Group>
                                    {
                                        selectedNetworks.map((network, index) =>
                                            <Label
                                                as="a"
                                                circular
                                                key={`network-${network}`}
                                                onClick={() => {
                                                    let updatedNetworks = selectedNetworks.slice();
                                                    updatedNetworks.splice(index, 1);
                                                    setSelectedNetworks(updatedNetworks);
                                                }}
                                            >
                                                {network}
                                                <Icon name="delete" />
                                            </Label>
                                        )
                                    }
                                </Label.Group>
                            </Segment>
                            <Segment basic style={{background: selectedProducts.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                <Divider horizontal>Products</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Products to Add"
                                        control={Dropdown}
                                        options={remainingProductOptions}
                                        value={productsToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        onChange={(event, {value}) => setProductsToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedProducts = selectedProducts.concat(productsToAdd);
                                            setSelectedAppIds(updatedProducts);
                                            setProductsToAdd([]);
                                        }}
                                        content="Add Selected Products"
                                        fluid
                                    />
                                </Form.Group>
                                <Label.Group>
                                    {
                                        selectedProducts.map((product, index) =>
                                            <Label
                                                as="a"
                                                circular
                                                key={`product-${product}`}
                                                onClick={() => {
                                                    let updatedProducts = selectedProducts.slice();
                                                    updatedProducts.splice(index, 1);
                                                    setSelectedProducts(updatedProducts);
                                                }}
                                            >
                                                {product}
                                                <Icon name="delete" />
                                            </Label>
                                        )
                                    }
                                </Label.Group>
                            </Segment>
                            <Segment basic style={{background: selectedPlatforms.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                <Divider horizontal>Platforms</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Platforms to Add"
                                        control={Dropdown}
                                        options={remainingPlatformOptions}
                                        value={platformsToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        onChange={(event, {value}) => setPlatformsToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedPlatforms = selectedPlatforms.concat(platformsToAdd);
                                            setSelectedPlatforms(updatedPlatforms);
                                            setPlatformsToAdd([]);
                                        }}
                                        content="Add Selected Platforms"
                                        fluid
                                    />
                                </Form.Group>
                                <Label.Group>
                                    {
                                        selectedPlatforms.map((platform, index) =>
                                            <Label
                                                as="a"
                                                circular
                                                key={`platform-${platform}`}
                                                onClick={() => {
                                                    let updatedPlatforms = selectedPlatforms.slice();
                                                    updatedPlatforms.splice(index, 1);
                                                    setSelectedPlatforms(updatedPlatforms);
                                                }}
                                            >
                                                {platform}
                                                <Icon name="delete" />
                                            </Label>
                                        )
                                    }
                                </Label.Group>
                            </Segment>
                            <Segment basic style={{background: selectedListNames.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                <Divider horizontal>List Names</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Add List Name"
                                        control={Input}
                                        value={listNameToAdd}
                                        fluid
                                        onChange={(event, {value}) => setListNameToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedListNames = selectedListNames.concat(listNameToAdd);
                                            setSelectedListNames(updatedListNames);
                                            setListNameToAdd("");
                                        }}
                                        content="Add List Name"
                                        fluid
                                    />
                                </Form.Group>
                                <Label.Group>
                                    {
                                        selectedListNames.map((listName, index) =>
                                            <Label
                                                as="a"
                                                circular
                                                key={`list-name-${listName}`}
                                                onClick={() => {
                                                    let updatedListNames = selectedListNames.slice();
                                                    updatedListNames.splice(index, 1);
                                                    setSelectedListNames(updatedListNames);
                                                }}
                                            >
                                                {listName}
                                                <Icon name="delete" />
                                            </Label>
                                        )
                                    }
                                </Label.Group>
                            </Segment>
                        </Form>
                        <Segment basic style={{background: "lightblue"}}>
                            <Divider horizontal>Schema</Divider>
                            <SchemaEditor
                                schema={schema}
                                setSchema={setSchema}
                            />
                        </Segment>
                    </>
            }
        </Container>
    );
};
