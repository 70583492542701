import React, {useState, useEffect} from "react";
import StateBasedPlayMetadataDataProvider from "../../../Services/StateBasedPlayDataProvider";
import {Grid} from "semantic-ui-react";
import ListSchemataDetailView from "./ListSchemataDetailView";
import ListSchemataSelector from "./ListSchemataSelector";

export default function ListSchemataMasterDetailView(props) {
    const [allNames, setAllNames] = useState([]);
    const [listSchemata, setListSchemata] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState({});
    const [schemaNames, setSchemaNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [newClicked, setNewClicked] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isSelectedSchemaUpdated, setIsSelectedSchemaUpdated] = useState(false);
    const [schemataRearranged, setSchemataRearranged] = useState(false);

    useEffect(function initializeListSchemata() {
        StateBasedPlayMetadataDataProvider.init({baseURL: process.env.SBP_URL});
        refreshListSchemata();
    }, []);

    useEffect(function updateAllNames() {
        const updatedAllNames = [];
        for (const schema of listSchemata) {
            if (schema.hasOwnProperty("name") && schema.name !== selectedSchema.name) {
                updatedAllNames.push(schema.name);
            }
        }
        setAllNames(updatedAllNames);
    }, [JSON.stringify(selectedSchema), JSON.stringify(listSchemata)]);

    useEffect(function generateNames() {
        const updatedSchemaNames = [];
        for (const schema of listSchemata) {
            if (schema.hasOwnProperty("name") && selectedSchema.hasOwnProperty("name") && schema.name !== selectedSchema.name) {
                updatedSchemaNames.push(schema.name);
            }
        }
        setSchemaNames(updatedSchemaNames);
    }, [JSON.stringify(listSchemata), JSON.stringify(selectedSchema)]);

    useEffect(function updateUnsavedChanges() {
        console.log("are there unsaved changes? ", schemataRearranged || isSelectedSchemaUpdated);
        setUnsavedChanges(schemataRearranged || isSelectedSchemaUpdated);
    }, [schemataRearranged, isSelectedSchemaUpdated]);


    useEffect(function onSelectSchema() {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        setNewClicked(false);
    }, [newClicked, JSON.stringify(selectedSchema)]);

    useEffect(function onClickNew() {
        if (newClicked) {
            const newSetting = {
                ruleId: "",
                index: listSchemata.length
            };
            setListSchemata(listSchemata.slice().concat(Object.assign({}, newSetting)));
            setSelectedSchema(Object.assign({}, newSetting));
            setSaveSuccessMessage("");
        }
    }, [newClicked]);

    useEffect(function onSettingsUpdate() {
        setSchemataRearranged(false);
        for (let i = 0; i < listSchemata.length; i++) {
            if (listSchemata[i].index !== i) {
                setSchemataRearranged(true);
                break;
            }
        }
    }, [JSON.stringify(listSchemata)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(schemataRearranged || isSelectedSchemaUpdated);
    }, [schemataRearranged, isSelectedSchemaUpdated]);

    const refreshListSchemata = () => {
        setLoading(true);
        setLoadingErrorMessage("");
        StateBasedPlayMetadataDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("listSchemata")) {
                const schemata = response.listSchemata.slice();
                const enrichedListSchemata = enrichListSchemata(schemata);
                setListSchemata(enrichedListSchemata);
            } else {
                setListSchemata([]);
                setLoadingErrorMessage("There was an error loading list schemata.");
            }
        }).catch(error => {
            console.error(error);
            setLoadingErrorMessage("There was an error loading list schemata.");
        }).finally(() => {
            setLoading(false);
        });
    };

    const enrichListSchemata = schemata => {
        for (let i = 0; i < schemata.length; i++) {
            schemata[i].index = i;
            const b64Schema = schemata[i].schema;
            try {
                const decodedSchema = atob(b64Schema);
                schemata[i].schema = JSON.parse(decodedSchema);
            } catch (e) {}


            if (selectedSchema.hasOwnProperty("name") && selectedSchema.name === listSchemata[i].name) {
                setSelectedSchema(Object.assign({}, schemata[i]));
            }
        }
        console.log("list schemata: ", schemata);
        return schemata;
    }

    const onClickRefresh = () => {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        refreshListSchemata();
    };

    const onClickSave = schema => {
        console.log(schema);
        setSaveErrorMessage("");
        setLoadingErrorMessage("");

        const updatedListSchemata = listSchemata.slice();

        for (let i = 0; i < updatedListSchemata.length; i++) {
            if (updatedListSchemata[i].index === schema.index) {
                updatedListSchemata[i] = Object.assign({}, schema);
            } else {
                updatedListSchemata[i].schema = btoa(JSON.stringify(updatedListSchemata[i].schema));
            }
        }

        for (const setting of updatedListSchemata) {
            delete setting.index;
        }

        setSaving(true);
        StateBasedPlayMetadataDataProvider.setMetadata("listSchemata", updatedListSchemata).then(response => {
            console.log(response);
            if (response.success) {
                setUnsavedChanges(false);
                if (response.metadata && response.metadata.listSchemata) {
                    const schemata = response.metadata.listSchemata.slice();
                    const enrichedListSchemata = enrichListSchemata(schemata);
                    setListSchemata(enrichedListSchemata);
                }
                setSaveSuccessMessage("The list schemata were saved successfully.");
            } else {
                setSaveErrorMessage("There was an error saving the list schemata.");
            }
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving the list schemata.");
        }).finally(() => {
            setSaving(false);
        });
    };

    const onClickDelete = index => {
        setSaveErrorMessage("");
        setSaveSuccessMessage("");

        const updatedListSchemata = listSchemata.slice();
        updatedListSchemata.splice(index, 1);

        setSaving(true);
        StateBasedPlayMetadataDataProvider.setMetadata("listSchemata", updatedListSchemata).then(response => {
            console.log(response);
            setSaveSuccessMessage("The selected list schema was successfully deleted.");
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error deleting the selected list schema.");
        }).finally(() => {
            setSaving(false);
            refreshListSchemata();
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={4}>
                <ListSchemataSelector
                    loading={loading}
                    listSchemata={listSchemata}
                    setListSchemata={setListSchemata}
                    refreshListSchemata={onClickRefresh}
                    selectedSchema={selectedSchema}
                    setSelectedSchema={setSelectedSchema}
                    setNewClicked={setNewClicked}
                    setUnsavedChanges={setUnsavedChanges}
                />
            </Grid.Column>
            <Grid.Column width={12} style={{maxHeight: "92vh", overflowY: "auto", overflowX: "hidden"}}>
                <ListSchemataDetailView
                    allNames={allNames}
                    loading={loading}
                    loadingErrorMessage={loadingErrorMessage}
                    saving={saving}
                    saveErrorMessage={saveErrorMessage}
                    saveSuccessMessage={saveSuccessMessage}
                    newClicked={newClicked}
                    selectedSchema={selectedSchema}
                    onClickSave={onClickSave}
                    onClickDelete={onClickDelete}
                    unsavedChanges={unsavedChanges}
                    isSelectedSchemaUpdated={isSelectedSchemaUpdated}
                    setIsSelectedSchemaUpdated={setIsSelectedSchemaUpdated}
                    schemaNames={schemaNames}
                    module={props.module}
                    service={props.service}
                    user={props.user}
                    permissions={props.permissions}
                />
            </Grid.Column>
        </Grid>
    );
};
