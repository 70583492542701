import React, {Component} from "react";
import SpotlightDataProvider from "../../../Services/SpotlightDataProvider";
import {Container, Grid, Header, Button, Icon} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import SpotlightMetadataDetailPane from "./SpotlightMetadataDetailPane";

export default class SpotlightMetadataMasterDetailView extends Component {
    constructor(props) {
        super(props);

        SpotlightDataProvider.init(process.env.SPOTLIGHT_URL);

        this.state = {
            newClicked: true,
            selectedAppId: "",
            loading: true,
            apps: [],
            authorized: false
        };

        this.loadData = this.loadData.bind(this);
        this.onClickNew = this.onClickNew.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.updateSelectedAppID = this.updateSelectedAppID.bind(this);
    }

    componentDidMount() {
        this.props.checkIfAuthorized(this.props.permissionsRequired).then(authorized => {
            if (authorized) {
                this.loadData();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.authenticated !== nextProps.authenticated) {
            console.log("(SpotlightMetadataMasterDetailView.componentWillReceiveProps)");
            this.loadData();
        }
    }

    updateSelectedAppID = event => this.setState({selectedAppID: event.target.value});

    onClickNew() {
        this.setState({
            selectedAppID: "",
            newClicked: true
        });
    }

    onClickSave(validatedAppMetadata) {
        SpotlightDataProvider.update(validatedAppMetadata.appId, validatedAppMetadata).catch(error => {
            console.error(`(SpotlightMetadataMasterDetailView.onClickSave) error saving metadata for app ${JSON.stringify(validatedAppMetadata)}`, error)
            this.props.toast("Error", `Error saving metadata for app ID ${this.state.selectedAppID}.`)
        }).then(response => {
            console.log("(SpotlightMetadataMasterDetailView.onClickSave) response from server: ", response);
            if (response.error) {
                console.log(`(SpotlightMetadataMasterDetailView.onClickSave) error saving metadata for app ${JSON.stringify(validatedAppMetadata)}`, response.error);
                this.props.toast("Error", `Could not save metadata for app ID ${this.state.selectedAppID}`, "error");
            } else {
                this.props.toast("Success", `Saved metadata for app ID ${this.state.selectedAppID}`, "success");
                this.loadData();
            }
        });
    }

    loadData() {
        this.props.checkIfAuthorized(["urn:all:aspen-power"]).catch(error => {
            console.error(error);
        }).then(authorized => {
            if (authorized) {
                this.setState({loading: true, newClicked: false}, () => {
                    SpotlightDataProvider.getAll().then(data => {
                        if (data.error) {
                            this.setState({
                                data: []
                            });
                            this.props.toast("Error Retrieving Data", data.error.message);
                        } else {
                            this.setState({data});
                        }
                    }).catch(error => {
                        this.props.toast("Error Retrieving Data", error.message);
                    }).finally(() => this.setState({loading: false}));
                });
            } else {
                this.props.toast("Error", "403 Unauthorized");
            }
        });
    }

    render() {
        let that = this;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={11} className="masterContainer">
                        <Grid>
                            <Grid.Column floated="right" width={16}>
                                <Container fluid textAlign="right" className="actionBarContainer">
                                    <Button onClick={this.loadData} size="mini"><Icon name="refresh" />Refresh</Button>
                                    <Button onClick={this.onClickNew} size="mini"><Icon name="plus" />Create New</Button>
                                </Container>
                            </Grid.Column>
                        </Grid>
                        <ReactTable
                            columns={[
                                { Header: "App ID", accessor: "appId" },
                                {
                                    Header: "Categories Allowed",
                                    accessor: "categoryAllowed",
                                    Cell: ({value}) => <span>{Array.isArray(value) ? value.join(", ") : value}</span>
                                },
                                {
                                    Header: "Events Allowed",
                                    accessor: "eventsAllowed",
                                    Cell: ({value}) => <span>{Array.isArray(value) ? value.join(", ") : value}</span>
                                },
                                { Header: "Flush Interval", accessor: "flushInterval" },
                                { Header: "Hello Ping Interval", accessor: "helloPingInterval" },
                                { Header: "Max Batch Size", accessor: "maxBatchSize" },
                                {
                                    Header: "Throttle Level",
                                    accessor: "throttleLevel",
                                    Cell: ({value}) => <span>{Array.isArray(value) ? value.join(", ") : value}</span>
                                }
                            ]}
                            data={this.state.data}
                            loading={this.state.loading}
                            filterable
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        that.setState({selectedAppID: rowInfo.row.appId, newClicked: false});
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && that.state.selectedAppID === rowInfo.row.appId ? "rgba(65, 83, 175, .5)" : ""
                                    }
                                }
                            }}
                            style={{height: "75vh"}}
                        />
                    </Grid.Column>
                    <Grid.Column width={5} className="detailsContainer">
                        <SpotlightMetadataDetailPane
                            selectedAppID={this.state.selectedAppID}
                            updateSelectedAppID={this.updateSelectedAppID}
                            onClickSave={this.onClickSave}
                            isNew={this.state.newClicked}
                            toast={this.props.toast}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
