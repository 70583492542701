import React, { Component } from "react";
import {Button, Form} from "semantic-ui-react";
import SpotlightDataProvider from "../../../Services/SpotlightDataProvider";

export default class SpotlightMetadataDetailPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryAllowed: "",
            eventsAllowed: "",
            flushInterval: "",
            helloPingInterval: "",
            maxBatchSize: "",
            throttleLevel: ""
        };

        this.changeCategoryAllowed = this.changeCategoryAllowed.bind(this);
        this.changeEventsAllowed = this.changeEventsAllowed.bind(this);
        this.changeFlushInterval = this.changeFlushInterval.bind(this);
        this.changeHelloPingInterval = this.changeHelloPingInterval.bind(this);
        this.changeMaxBatchSize = this.changeMaxBatchSize.bind(this);
        this.changeThrottleLevel = this.changeThrottleLevel.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.state);
        if (this.props.isNew && !prevProps.isNew) {
            this.setState({
                categoryAllowed: "",
                eventsAllowed: "",
                flushInterval: 10,
                helloPingInterval: 600,
                maxBatchSize: 200,
                throttleLevel: ""
            });
        }

        if (!this.props.isNew && this.props.selectedAppID !== prevProps.selectedAppID) {
            SpotlightDataProvider.get(this.props.selectedAppID).then(app => {
                this.setState({
                    categoryAllowed: Array.isArray(app.categoryAllowed) ? app.categoryAllowed.join(",") : app.categoryAllowed,
                    eventsAllowed: Array.isArray(app.eventsAllowed) ? app.eventsAllowed.join(",") : app.eventsAllowed,
                    flushInterval: app.flushInterval,
                    helloPingInterval: app.helloPingInterval,
                    maxBatchSize: app.maxBatchSize,
                    throttleLevel: Array.isArray(app.throttleLevel) ? app.throttleLevel.join(","): app.throttleLevel
                });
            }).catch(error => {
                console.error(`(SpotlightMetadataDetailPane.componentDidUpdate) ${error}`);
            });
        }
    }

    changeCategoryAllowed = event => this.setState({categoryAllowed: event.target.value});
    changeEventsAllowed = event => this.setState({eventsAllowed: event.target.value});
    changeFlushInterval = event => this.setState({flushInterval: event.target.value});
    changeHelloPingInterval = event => this.setState({helloPingInterval: event.target.value});
    changeMaxBatchSize = event => this.setState({maxBatchSize: event.target.value});
    changeThrottleLevel = event => this.setState({throttleLevel: event.target.value});

    onClickSave = () => {
        console.log("(SpotlightMetadataDetailPane.onClickSave) saving app: ", this.state);
        const flushInterval = parseInt(this.state.flushInterval);
        const helloPingInterval = parseInt(this.state.helloPingInterval);
        const maxBatchSize = parseInt(this.state.maxBatchSize);

        if (!this.props.selectedAppID) {
            this.props.toast("Error", "Please use a valid app ID.");
        } else if (!this.state.categoryAllowed) {
            this.props.toast("Error", "Please set the Category Allowed to either '*' for all or a comma-separated list.");
        } else if (!this.state.eventsAllowed) {
            this.props.toast("Error", "Please set the Events Allowed to either '*' for all or a comma-separated list.");
        } else if (flushInterval <= 0) {
            this.props.toast("Error", "Please set the Flush Interval to a value greater than zero.");
        } else if (helloPingInterval <= 0) {
            this.props.toast("Error", "Please set the Hello Ping Interval to a value greater than zero.");
        } else if (maxBatchSize <= 0) {
            this.props.toast("Error", "Please set the Max Batch Size to a value greater than zero.");
        } else if (!this.state.throttleLevel) {
            this.props.toast("Error", "Please set the Throttle Level to either '*' to allow all or a comma-separated list to restrict messages to only those.");
        } else {
            const app = {
                appId: this.props.selectedAppID,
                categoryAllowed: this.state.categoryAllowed,
                eventsAllowed: this.state.eventsAllowed,
                flushInterval,
                helloPingInterval,
                maxBatchSize,
                throttleLevel: this.state.throttleLevel
            };

            if (app.categoryAllowed !== "*") {
                app.categoryAllowed = app.categoryAllowed.split(",");
            }

            if (app.eventsAllowed !== "*") {
                app.eventsAllowed = app.eventsAllowed.split(",");
            }

            if (app.throttleLevel !== "*") {
                app.throttleLevel = app.throttleLevel.split(",");
            }

            this.props.onClickSave(app);
        }
    };

    render() {
        return (
            <Form>
                <Button onClick={this.onClickSave}>Save</Button>
                <br />
                <br />
                <Form.Field>
                    <Form.Input label="App ID" focus value={this.props.selectedAppID} description="test" disabled={!this.props.isNew} onChange={this.props.updateSelectedAppID} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Categories Allowed" focus value={this.state.categoryAllowed} onChange={this.changeCategoryAllowed} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Events Allowed" focus value={this.state.eventsAllowed} onChange={this.changeEventsAllowed} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Flush Interval" type="number" focus value={this.state.flushInterval} onChange={this.changeFlushInterval} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Hello Ping Interval" type="number" focus value={this.state.helloPingInterval} onChange={this.changeHelloPingInterval} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Max Batch Size" type="number" focus value={this.state.maxBatchSize} onChange={this.changeMaxBatchSize} />
                </Form.Field>
                <Form.Field>
                    <Form.Input label="Throttle Level" focus value={this.state.throttleLevel} onChange={this.changeThrottleLevel} />
                </Form.Field>
            </Form>
        );
    }
}
