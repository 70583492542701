import React, {useState, useEffect} from "react";
import StateBasedPlayDataProvider from "../../../Services/StateBasedPlayDataProvider";
import {Grid} from "semantic-ui-react";
import ContentSchemataDetailView from "./ContentSchemataDetailView";
import ContentSchemataSelector from "./ContentSchemataSelector";

export default function ContentSchemataMasterDetailView(props) {
    const [contentSchemata, setContentSchemata] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState({});
    const [schemaNames, setSchemaNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [newClicked, setNewClicked] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isSelectedSchemaUpdated, setIsSelectedSchemaUpdated] = useState(false);
    const [schemataRearranged, setSchemataRearranged] = useState(false);

    useEffect(function initializeContentSchemata() {
        StateBasedPlayDataProvider.init({baseURL: process.env.SBP_URL});
        refreshContentSchemata();
    }, []);

    useEffect(function generateNames() {
        const updatedSchemaNames = [];
        if (Array.isArray(contentSchemata) && contentSchemata.length > 0) {
            for (const schema of contentSchemata) {
                if (schema.hasOwnProperty("name") && schema.name !== selectedSchema.name) {
                    updatedSchemaNames.push(schema.name);
                }
            }
        }

        setSchemaNames(updatedSchemaNames);
    }, [JSON.stringify(contentSchemata), JSON.stringify(selectedSchema)]);

    useEffect(function onSelectSchema() {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        setNewClicked(false);
        setIsSelectedSchemaUpdated(false);
    }, [newClicked, JSON.stringify(selectedSchema)]);

    useEffect(function onClickNew() {
        if (newClicked) {
            const newSetting = {
                name: "",
                description: "",
                index: contentSchemata.length
            };
            setContentSchemata(contentSchemata.slice().concat(Object.assign({}, newSetting)));
            setSelectedSchema(Object.assign({}, newSetting));
            setSaveSuccessMessage("");
        }
    }, [newClicked]);

    useEffect(function onSettingsUpdate() {
        setSchemataRearranged(false);
        for (let i = 0; i < contentSchemata.length; i++) {
            if (contentSchemata[i].index !== i) {
                setSchemataRearranged(true);
                break;
            }
        }
    }, [JSON.stringify(contentSchemata)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(schemataRearranged || isSelectedSchemaUpdated);
    }, [schemataRearranged, isSelectedSchemaUpdated]);

    const refreshContentSchemata = () => {
        setLoading(true);
        setLoadingErrorMessage("");
        StateBasedPlayDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("contentSchemata")) {
                const schemata = response.contentSchemata.slice();
                const enrichedContentSchemata = enrichContentSchemata(schemata);
                setContentSchemata(enrichedContentSchemata);
            } else {
                setContentSchemata([]);
                setLoadingErrorMessage("There was an error loading content schemata.");
            }
        }).catch(error => {
            console.error(error);
            setLoadingErrorMessage("There was an error loading content schemata.");
        }).finally(() => {
            setLoading(false);
        });
    };

    const enrichContentSchemata = schemata => {
        for (let i = 0; i < schemata.length; i++) {
            schemata[i].index = i;
            const b64Schema = schemata[i].schema;
            try {
                const decodedSchema = atob(b64Schema);
                schemata[i].schema = JSON.parse(decodedSchema);
            } catch (e) {}

            if (selectedSchema.hasOwnProperty("name") && selectedSchema.name === contentSchemata[i].name) {
                setSelectedSchema(Object.assign({}, schemata[i]));
            }
        }
        console.log("content schemata: ", schemata);
        return schemata;
    }

    const onClickRefresh = () => {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        refreshContentSchemata();
    };

    const onClickSave = schema => {
        console.log(schema);
        setSaveErrorMessage("");
        setLoadingErrorMessage("");

        const updatedContentSchemata = contentSchemata.slice();

        for (let i = 0; i < updatedContentSchemata.length; i++) {
            if (updatedContentSchemata[i].index === schema.index) {
                updatedContentSchemata[i] = Object.assign({}, schema);
            } else {
                updatedContentSchemata[i].schema = btoa(JSON.stringify(updatedContentSchemata[i].schema));
            }
        }

        for (const setting of updatedContentSchemata) {
            delete setting.index;
        }

        setSaving(true);
        StateBasedPlayDataProvider.setMetadata("contentSchemata", updatedContentSchemata).then(response => {
            console.log(response);
            if (response.success) {
                setUnsavedChanges(false);
                if (response.metadata && response.metadata.contentSchemata) {
                    const schemata = response.metadata.contentSchemata.slice();
                    const enrichedContentSchemata = enrichContentSchemata(schemata);
                    setContentSchemata(enrichedContentSchemata);
                }
                setSaveSuccessMessage("The content schemata were saved successfully.");
            } else {
                setSaveErrorMessage("There was an error saving the content schemata.");
            }
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving the content schemata.");
        }).finally(() => {
            setSaving(false);
        });
    };

    const onClickDelete = index => {
        setSaveErrorMessage("");
        setSaveSuccessMessage("");

        const updatedContentSchemata = contentSchemata.slice();
        updatedContentSchemata.splice(index, 1);

        setSaving(true);
        StateBasedPlayDataProvider.setMetadata("contentSchemata", updatedContentSchemata).then(response => {
            console.log(response);
            setSaveSuccessMessage("The selected content schema was successfully deleted.");
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error deleting the selected content schema.");
        }).finally(() => {
            setSaving(false);
            refreshContentSchemata();
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={4}>
                <ContentSchemataSelector
                    loading={loading}
                    contentSchemata={contentSchemata}
                    setContentSchemata={setContentSchemata}
                    refreshContentSchemata={onClickRefresh}
                    selectedSchema={selectedSchema}
                    setSelectedSchema={setSelectedSchema}
                    setNewClicked={setNewClicked}
                    setUnsavedChanges={setUnsavedChanges}
                />
            </Grid.Column>
            <Grid.Column width={12} style={{maxHeight: "92vh", overflowY: "auto", overflowX: "hidden"}}>
                <ContentSchemataDetailView
                    loading={loading}
                    loadingErrorMessage={loadingErrorMessage}
                    saving={saving}
                    saveErrorMessage={saveErrorMessage}
                    saveSuccessMessage={saveSuccessMessage}
                    newClicked={newClicked}
                    selectedSchema={selectedSchema}
                    onClickSave={onClickSave}
                    onClickDelete={onClickDelete}
                    unsavedChanges={unsavedChanges}
                    isSelectedSchemaUpdated={isSelectedSchemaUpdated}
                    setIsSelectedSchemaUpdated={setIsSelectedSchemaUpdated}
                    schemaNames={schemaNames}
                    schemataRearranged={schemataRearranged}
                    service={props.service}
                    module={props.module}
                    user={props.user}
                    permissions={props.permissions}
                />
            </Grid.Column>
        </Grid>
    );
};
